import accountx from '@/store/modules/accountx';
import store from '@/store';
import {
	PermissionsGroup, GdbPathMinimum, GdbPath, FilterableCardName,
} from '@/store/models.def';
import { Dictionary } from 'vue-router/types/router';
import fakeDb from '@/util/fakeget/firestoreFakeGet';
import gdbx from '@/store/modules/gdbx';
import moment, { Moment } from 'moment';
import fb from '@/store/gobi-firestore';
import firebase from 'firebase/app';

const storage: firebase.storage.Storage = fb.storage;

const csvNames: { [U in FilterableCardName]?: string } = {
	transactions: 'transactions',
	cashflow: 'cashflow',
	stockBalances: 'stockBalances',
	sales: 'sales',
	creditNotes: 'creditNotes',
	purchaseReturn: 'purchaseReturn',
	quotations: 'quotations',
	salesOrder: 'salesOrder',
	purchases: 'purchases',
	stockTrans: 'stockTrans',
	stockUtdBalance: 'stockUtdBalance',
	stockAging: 'stockAging',
	stockAvailability: 'stockAvailability',
	stockSales: 'stockSales',
	stockPurchase: 'stockPurchase',
	stockMovement: 'stockMovement',
	stockReorderAdvise: 'stockReorderAdvise',
	stockBatchExpiry: 'stockBatchExpiry',
	projectCashFlow: 'projectCashFlow',
	projectStockGroup: 'projectStockGroup',
	projectCosting: 'projectCosting',
	stockPurchaseOrder: 'stockPurchaseOrder',
	purchaseOrder: 'purchaseOrder',
	purchaseRequest: 'purchaseRequest',
	customers: 'customers',
	customerIvDns: 'customerIvDn',
	customerKos: 'customerKo',
	customerNetSales: 'customerNetSales',
	customerSalesTrans: 'customerSalesTrans',
	customerTrades: 'customerTrade',
	suppliers: 'suppliers',
	supplierPiSds: 'supplierPiSd',
	supplierKos: 'supplierKo',
	supplierNetCogs: 'supplierNetCogs',
	supplierPurchaseTrans: 'supplierPurchaseTrans',
	supplierTrades: 'supplierTrade',
	dashboardData: 'todayYesterday',
	dashboardStockGroupSales: 'stockGroupSales',
	dashboardCustomersDue: 'customerDue',
	dashboardSuppliersDue: 'supplierDue',
	dashboardSales: 'salesIvCsDnCn',
	dashboardQuotation: 'salesQt',
	dashboardCashBalance: 'cashBalance',
	dashboardCashFlow: 'cashFlow',
	dashboardApArDue: 'arApDue',
	dashboardArCustomer: 'diyArCustomer',
	diyDailyProjectSales: 'diyDailyProjectSales',
	diyStockPackingBudget: 'diyStockPackingBudget',
	diySalesSummary: 'diySalesSummary',
	diyMaintainStockItem: 'diyMaintainStockItem',
	diySupportRenewal: 'diySupportRenewal',
	closingStockBalance: 'closingStockBalance',
	system: 'system',
	globalAgents: 'globalAgents',
	globalStocks: 'globalStocks',
	globalAcc: 'globalAcc',
	globalCustomers: 'globalCustomers',
	globalSuppliers: 'globalSuppliers',
	globalProjects: 'globalProjects',
	globalLocations: 'globalLocations',
	globalArea: 'globalArea',
	globalCompanyCategory: 'globalCompanyCategory',
};

const cardNameToZip: Dictionary<PermissionsGroup> = {
	sales: 'sales',
	creditNotes: 'sales',
	purchaseReturn: 'purchases',
	purchases: 'purchases',
	quotations: 'quotations',
	salesOrder: 'sales',
	transactions: 'transactions',
	cashflow: 'transactions',
	stockBalances: 'transactions',
	payroll: 'payroll',
	stockTrans: 'stocks',
	stockUtdBalance: 'stocks',
	stockPurchaseOrder: 'stocks',
	stockAging: 'stocks',
	stockAvailability: 'stocks',
	stockSales: 'stocks',
	stockPurchase: 'stocks',
	stockMovement: 'stocks',
	stockReorderAdvise: 'stocks',
	stockBatchExpiry: 'stocks',
	closingStockBalance: 'stocks',
	purchaseOrder: 'purchases',
	purchaseRequest: 'purchases',
	projectCashFlow: 'transactions',
	projectStockGroup: 'transactions',
	projectCosting: 'transactions',
	customers: 'customers', customerAnalysis: 'customers',
	customerIvDns: 'customers', customerKos: 'customers',
	customerSalesTrans: 'customers', customerTrades: 'customers',
	customerNetSales: 'customers',
	suppliers: 'suppliers', supplierAnalysis: 'suppliers',
	supplierKos: 'suppliers', supplierPiSds: 'suppliers',
	supplierPurchaseTrans: 'suppliers', supplierTrades: 'suppliers',
	supplierNetCogs: 'suppliers',
	globalAgents: 'global',
	globalStocks: 'global',
	globalAcc: 'global',
	globalCustomers: 'global',
	globalSuppliers: 'global',
	globalProjects: 'global',
	globalLocations: 'global',
	globalArea: 'global',
	globalCompanyCategory: 'global',
	dashboardData: 'dashboard',
	dashboardStockGroupSales: 'dashboard',
	dashboardCustomersDue: 'dashboard',
	dashboardSuppliersDue: 'dashboard',
	dashboardSales: 'dashboard',
	dashboardQuotation: 'dashboard',
	dashboardCashFlow: 'dashboard',
	dashboardCashBalance: 'dashboard',
	dashboardApArDue: 'dashboard',
	dashboardArCustomer: 'dashboard',
	diyDailyProjectSales: 'dashboard',
	diyStockPackingBudget: 'dashboard',
	diySalesSummary: 'dashboard',
	diyMaintainStockItem: 'dashboard',
	diySupportRenewal: 'dashboard',
	system: 'global',
};

export class GobiDatabase {
	public static instance = new GobiDatabase();

	public accountId = '';

	public permissionGroupLoaded: { [T in PermissionsGroup]?: boolean } = {};
	public permissionGroupPath: { [T in PermissionsGroup]?: string } = {};

	public currencySymbol: string = 'MYR';
	public numDecimal: number = 2;
	public openingStockAmount: number = 0;
	public accountStartingDate: Moment = moment('01/01/2019', 'DD MMM YYYY');


	constructor() {
		(window as any).gdb = this;
		store.watch(
			(states, getters) => getters['accountx/currentAccountId'],
			(newValue, oldValue) => {
				if (!newValue || newValue === 'loading') {
					this.dispose();
				} else if (newValue && newValue !== 'loading') {
					this.accountId = newValue;
				}
			});
	}

	public dispose() {
		Object.keys(this.permissionGroupPath).forEach((key) => {
			this._disposeUrl(key as any as PermissionsGroup);
		});
		this.permissionGroupPath = {};
	}

	public async getUrl(cardType: FilterableCardName) {
		const zipName = cardNameToZip[cardType];

		if (!zipName) { throw Error('unrecognized card type: ' + cardType); }

		if ((zipName !== 'global' && zipName !== 'dashboard') && !accountx.myPermissions![zipName]) {
			throw Error('This GOBI user has insufficient permission: ' + zipName);
		}
		const downloadUrl = await this._loadUrl(cardType, zipName);

		if (!downloadUrl) { throw Error(`Download URL for ${cardType}.csv not found.`); }
		return downloadUrl;
	}


	public processSystem(json: any) {
		// const json = this._csvToJson(file);
		if (json) {
			this.currencySymbol = json.find((o) => o.key === 'CURRENCY').val;
			const date = json.find((o) => o.key === 'AccountingStartPeriod').val;
			const df = json.find((o) => o.key === 'DF_AccountingValue').val;
			this.accountStartingDate = moment(date, 'DD MMM YYYY');
			const splited = df.split(';')[0].split('.');
			if (!splited[1]) {
				this.numDecimal = 0;
			} else {
				this.numDecimal = splited[1].length;
			}
		} else {
			this.currencySymbol = 'MYR';
			this.accountStartingDate = this.accountStartingDate;
			this.numDecimal = 2;
		}

		gdbx.updateProperties({
			currencySymbol: this.currencySymbol,
			accountStartingDate: this.accountStartingDate,
			numDecimal: this.numDecimal,
		});
	}

	protected async _loadUrl(cardType: FilterableCardName, group: PermissionsGroup) {
		const csvName = csvNames[cardType];
		if (!csvName) { return null; }
		this._disposeUrl(group);
		const accountData = accountx.fullAccountData;
		if (!accountData) { return null; }
		const { accountId } = accountData;
		const gdbPath = accountData[`${group}LastAvailable`] as GdbPathMinimum | undefined;
		if (!gdbPath) { return null; }

		let bucketUrl: string | null;
		let downloadUrl: string | null;
		if (group === 'global' || group === 'dashboard') {
			if (!gdbPath.csvs || !gdbPath.csvs[csvName]) {
				bucketUrl = null;
				downloadUrl = null;
			} else {
				bucketUrl = gdbPath.csvs[csvName]!.bucketUrl;
				downloadUrl = gdbPath.csvs[csvName]!.downloadUrl;
			}
		} else {
			const doc = await fakeDb.doc(`/accounts/${accountId}/${group}/${gdbPath.gdbid}`).get();

			const fullGdbPath: GdbPath = doc;

			if (!fullGdbPath.csvs || !fullGdbPath.csvs[csvName]) {
				bucketUrl = null;
				downloadUrl = null;
			} else {
				bucketUrl = fullGdbPath.csvs[csvName]!.bucketUrl;
				downloadUrl = fullGdbPath.csvs[csvName]!.downloadUrl;
			}
			// console.log(doc);
		}

		if (!downloadUrl) {
			gdbx.updateVersionIncompatible(true);
			return null;
		}
		// const url = await storage.refFromURL(`gs://gobi-pro.appspot.com/${bucketUrl}`).getDownloadURL()
		// console.log(url);
		// console.log(downloadUrl);
		return downloadUrl;
	}

	protected _disposeUrl(group: PermissionsGroup) {
		const { accountId } = this;
		const lastGdbid = this.permissionGroupPath[group];
		if (lastGdbid) {
			fakeDb.doc(`/accounts/${accountId}/${group}/${lastGdbid}`).dispose();
			delete this.permissionGroupPath[group];
		}
	}
}

export default new GobiDatabase();
