import * as comlink from 'comlink';
import {
	FdwGetHeadWorkerType, FdwDisposeWorkerType, FdwLoadCardsWorkerType,
	FdwGetCardsByHeadWorkerType, FdwGetSumByHeadWorkerType,
	FdwGetNumberByHeadWorkerType, FdwGetSetByHeadWorkerType, FdwGetMinMaxByHeadWorkerType,
} from '@/worker/filter.worker.type';
import { Dictionary } from 'vue-router/types/router';
import {
	PermissionsGroup, FilterableCardName,
	ConditionType, GdbLoadProgress, AccountData, FilterDbSubmission, CardLoadProgress,
} from '@/store/models.def';
import gdb from '@/store/gdbz-v2';
import store from '@/store';
import { FilteredDatabaseHead } from '@/worker/fd/FilteredDatabaseHead';
import gdbx from '@/store/modules/gdbx';
import { StateHistory } from '@/util/stateHistory';

interface PromiseData {
	resolve: () => void,
	reject: (error: Error) => void,
	tag?: string,
}
type PermissionGroupLoadPromises = {
	[T in PermissionsGroup]?: PromiseData[]
};


const worker = comlink.wrap<{
	loadCsvToCards: FdwLoadCardsWorkerType,
	getHead: FdwGetHeadWorkerType,
	getCardsByHead: FdwGetCardsByHeadWorkerType,
	getCountByHead: FdwGetNumberByHeadWorkerType,
	getSumByHead: FdwGetSumByHeadWorkerType,
	getSetByHead: FdwGetSetByHeadWorkerType,
	getMinMaxByHead: FdwGetMinMaxByHeadWorkerType,
	dispose: FdwDisposeWorkerType,
}>(
	new Worker('./filter.worker.ts', {
		type: 'module',
	}),
);

export class FilteredDatabaseWorker {
	public loadPromises: PermissionGroupLoadPromises = {};
	public cardLoadProgress: CardLoadProgress = {};
	public currentAccountId = '';

	public reportProgress = comlink.proxy((accountId: string, cardType: FilterableCardName, rowsLoaded: number) => {
		// console.log(cardType, rowsLoaded);
		if (accountId !== this.currentAccountId) { return; }
		this._updateLoadProgress(cardType, rowsLoaded);
	});

	constructor() {
		store.watch(
			(states, getters) => getters['accountx/currentAccountId'],
			(newValue, oldValue) => {
				StateHistory.clear();
				if (!newValue || newValue === 'loading') {
					// gdbx.dispose();

				} else if (newValue && newValue !== 'loading') {
					gdbx.dispose();
					worker.dispose();
					this.cardLoadProgress = {};
					for (const cardType in this.loadPromises) {
						if (this.loadPromises.hasOwnProperty(cardType)) {
							const promises = this.loadPromises[cardType] as PromiseData[];
							for (const promise of promises) {
								promise.reject(new Error('Interrupted'));
							}
						}
					}
					this.currentAccountId = newValue;
				}
			});

		store.watch((states: any, getters) => states.accountx && states.accountx.fullAccountData,
			(newValue: AccountData, oldValue: AccountData) => {
				if (newValue) {
					this._confirmLoaded('system');
					this._confirmLoaded('globalAgents');
					this._confirmLoaded('globalCustomers');
					this._confirmLoaded('globalStocks');
					this._confirmLoaded('globalSuppliers');
					this._confirmLoaded('globalProjects');
					this._confirmLoaded('globalLocations');
					this._confirmLoaded('globalAcc');
				}
			});
	}

	public async getHead(cardType: FilterableCardName, filterOptions?: Dictionary<ConditionType>) {

		const accountId = this.currentAccountId;
		try {
			await Promise.all([
				this._confirmLoaded('system'),
				this._confirmLoaded('globalAgents'),
				this._confirmLoaded('globalCustomers'),
				this._confirmLoaded('globalStocks'),
				this._confirmLoaded('globalSuppliers'),
				this._confirmLoaded('globalProjects'),
				this._confirmLoaded('globalLocations'),
				this._confirmLoaded('globalAcc'),
				this._confirmLoaded(cardType)]);
			if (accountId !== this.currentAccountId) { return new FilteredDatabaseHead(0); }
			return new FilteredDatabaseHead(await worker.getHead(cardType, filterOptions));

		} catch (e) {
			throw e;
		}
	}

	public async getCardsByHead(id: number) {
		return await worker.getCardsByHead(id);
	}
	public async getCountByHead(id: number) {
		return await worker.getCountByHead(id);
	}
	public async getSumByHead(id: number, property: string) {
		return await worker.getSumByHead(id, property);
	}
	public async getSetByHead(id: number, property: string) {
		return await worker.getSetByHead(id, property);
	}
	public async getMinMaxByHead(id: number, property: string) {
		return await worker.getMinMaxByHead(id, property);
	}
	/**
	 * Load the zip if it hasn't, return nothing if it succeeded or when it succeed
	 * @param zipName zip name
	 */
	private _confirmLoaded(cardType: FilterableCardName) {
		return new Promise((resolve, reject) => {
			if (this.cardLoadProgress[cardType] === undefined ||
				this.cardLoadProgress[cardType] === 'Idle' ||
				this.cardLoadProgress[cardType] === 'Failed') {
				try {
					this._loadCsv(cardType).then(() => {
						resolve();
					});
				} catch (e) {
					reject(e);
				}
			} else if (this.cardLoadProgress[cardType] === 'Completed') {
				resolve();
			} else {
				if (!this.loadPromises[cardType]) { this.loadPromises[cardType] = []; }
				this.loadPromises[cardType]!.push({ reject, resolve, tag: this.currentAccountId });
			}
		});
	}

	private async _loadCsv(cardType: FilterableCardName) {
		const accountId = this.currentAccountId;
		this._updateLoadProgress(cardType, 'Loading');
		try {
			const csvUrl = await gdb.getUrl(cardType);
			// const csvUrl = (cardType === 'sales' && this.currentAccountId === 'zqVRSDYi0idSZdf7JR4R') ?
			// 	'https://firebasestorage.googleapis.com/v0/b/gobi-pro.appspot.com/o/public%2Fsales.csv?alt=media'
			// 	: await gdb.getUrl(cardType);
			if (accountId !== this.currentAccountId) { return; }
			await worker.loadCsvToCards(this.currentAccountId, csvUrl, cardType, this.reportProgress);
			if (accountId !== this.currentAccountId) { return; }
			this._updateLoadProgress(cardType, 'Completed');
			if (cardType === 'stockBalances') {
				const head = new FilteredDatabaseHead(await worker.getHead('stockBalances'));
				const stockBalances = await head.getCards();
				if (accountId !== this.currentAccountId) { return; }
				const monthBefore = gdbx.accountStartingDate.clone().add(-1, 'month');
				const month = monthBefore.month() + 1;
				const year = monthBefore.year();
				const card = stockBalances.find((c) => c.month === month && c.year === year);
				const openingStockAmount = (card) ? card.amount : 0;
				gdbx.updateOpeningStockAmount(openingStockAmount);
			} else if (cardType === 'system') {
				const system = await (new FilteredDatabaseHead(await worker.getHead(cardType))).getCards();
				if (accountId !== this.currentAccountId) { return; }
				gdb.processSystem(system);
			} else if (
				cardType === 'globalAgents' ||
				cardType === 'globalStocks' ||
				cardType === 'globalCustomers' ||
				cardType === 'globalProjects' ||
				cardType === 'globalLocations' ||
				cardType === 'globalAcc' ||
				cardType === 'globalSuppliers') {
				const submission: FilterDbSubmission = {};
				submission[cardType] = await (new FilteredDatabaseHead(await worker.getHead(cardType))).getCards();
				if (accountId !== this.currentAccountId) { return; }
				gdbx.updateFilterDbs(submission);
			}
			const promises = this.loadPromises[cardType];
			if (promises) {
				for (const promise of promises) {
					promise.resolve();
				}
			}
		} catch (e) {
			const promises = this.loadPromises[cardType];
			if (promises) {
				for (const promise of promises) {
					promise.reject(e);
				}
			}
			this._updateLoadProgress(cardType, 'Failed');
			throw e;
		}
	}

	private _updateLoadProgress(cardType: FilterableCardName, status: GdbLoadProgress) {
		this.cardLoadProgress[cardType] = status;
		gdbx.m_updateCardLoadProgress(this.cardLoadProgress);
	}
}

export default new FilteredDatabaseWorker();













