import { Dictionary } from 'vue-router/types/router';
import { ConditionType, FilterableCardName, FilterLevel, NumberRangeOperator } from '@/store/models.def';
import gdbx from '@/store/modules/gdbx';
import moment from 'moment';
import fdw from '@/worker/fdw';

export class FdRef {

    public cardType: FilterableCardName;
    public filterOptions: Dictionary<ConditionType> = {};
    constructor(cardType: FilterableCardName) {
        this.cardType = cardType;
    }
    public agents(values: string[]) {
        const len = gdbx.allAgents.length;
        if (values.length !== len) {
            this.filterOptions.agent = { isAll: false, stringList: values };
        }
        return this;
    }
    public stockItems(values: string[]) {
        const len = gdbx.allStockItems.length;
        if (values.length !== len) {
            this.filterOptions.itemCode = { isAll: false, stringList: values };
        }
        return this;
    }
    public customers(values: string[]) {
        const len = gdbx.allCustomers.length;
        if (values.length !== len) {
            this.filterOptions.code = { isAll: false, stringList: values };
        }
        return this;
    }
    public suppliers(values: string[]) {
        const len = gdbx.allSuppliers.length;
        if (values.length !== len) {
            this.filterOptions.code = { isAll: false, stringList: values };
        }
        return this;
    }
    public projects(values: string[]) {
        const len = gdbx.allProjects.length;
        if (values.length !== len) {
            this.filterOptions.project = { isAll: false, stringList: values };
        }
        return this;
    }
    public dateRange(dateRange: [number, number], dateKey: string = 'date') {
        const currentOffset = new Date().getTimezoneOffset();
        const targetOffset = -480;

        // Check if currentOffset isn't the target
        if (currentOffset !== targetOffset) {
            // Calculate the difference in minutes and convert it to milliseconds
            const difference = (currentOffset - targetOffset) * 60 * 1000;

            // Adjust the date range by removing the difference
            dateRange = [dateRange[0] - difference, dateRange[1] - difference];
        }
        this.filterOptions[dateKey] = {
            isAll: false,
            numberOperators: [['>=', dateRange[0]], ['<=', dateRange[1]]],
        };
        return this;
    }
    public dateAsOf(date: number, dateKey: string = 'date') {
        const currentOffset = new Date().getTimezoneOffset();
        const targetOffset = -480;

        // Check if currentOffset isn't the target
        if (currentOffset !== targetOffset) {
            // Calculate the difference in minutes and convert it to milliseconds
            const difference = (currentOffset - targetOffset) * 60 * 1000;

            // Adjust the date range by removing the difference
            date = date - difference;
        }
        this.filterOptions[dateKey] = {
            isAll: false,
            numberOperators: [['>=', 0], ['<=', date]],
        };
        return this;
    }
    public year(year: number, dateKey: string = 'date') {
        const mmt = moment().year(year);
        const dateRange: [number, number] =
            [mmt.startOf('year').valueOf(), mmt.endOf('year').valueOf()];
        this.filterOptions[dateKey] = {
            isAll: false,
            numberOperators: [['>=', dateRange[0]], ['<=', dateRange[1]]],
        };
        return this;
    }
    public month(year: number, monthIndex: number, dateKey: string = 'date') {
        const mmt = moment().year(year).month(monthIndex);
        const dateRange: [number, number] =
            [mmt.startOf('month').valueOf(), mmt.endOf('month').valueOf()];
        this.filterOptions[dateKey] = {
            isAll: false,
            numberOperators: [['>=', dateRange[0]], ['<=', dateRange[1]]],
        };
        return this;
    }
    public date(year: number, monthIndex: number, date: number, dateKey: string = 'date') {
        const currentOffset = new Date().getTimezoneOffset();
        const targetOffset = -480;
        const mmt = moment().year(year).month(monthIndex).date(date);
        let dateRange: [number, number] =
            [mmt.startOf('day').valueOf(), mmt.endOf('day').valueOf()];

        // Check if currentOffset isn't the target
        if (currentOffset !== targetOffset) {
            // Calculate the difference in minutes and convert it to milliseconds
            const difference = (currentOffset - targetOffset) * 60 * 1000;

            // Adjust the date range by removing the difference
            dateRange = [dateRange[0] - difference, dateRange[1] - difference];
        }
        this.filterOptions[dateKey] = {
            isAll: false,
            numberOperators: [['>=', dateRange[0]], ['<=', dateRange[1]]],
        };
        return this;
    }
    public includes(key: string, stringList?: string[]) {
        const condition: ConditionType = {
            isAll: stringList ? false : true,
            filterType: 'string',
            stringList,
        };
        this.filterOptions[key] = condition;
        return this;
    }
    public numberRange(key: string, numberOperators: NumberRangeOperator) {
        const condition: ConditionType = {
            isAll: numberOperators ? false : true,
            filterType: 'number',
            numberOperators,
        };
        this.filterOptions[key] = condition;
        return this;
    }

    public clone() {
        const clone = new FdRef(this.cardType);

        clone.filterOptions = {};
        for (const key in this.filterOptions) {
            if (this.filterOptions.hasOwnProperty(key)) {
                const condition = this.filterOptions[key];
                const { isAll, filterType, stringList, numberOperators } = condition;
                clone.filterOptions[key] = {
                    isAll, filterType,
                };
                clone.filterOptions[key].stringList = stringList ? [...stringList] : undefined;
                clone.filterOptions[key].numberOperators = cloneOperator(numberOperators);
            }
        }
        function cloneOperator(o?: NumberRangeOperator) {
            if (!o) { return undefined; }
            const c: NumberRangeOperator = [
                [o[0][0], o[0][1]],
            ];
            if (o[1]) {
                c[1] = [o[1][0], o[1][1]];
            }
            return c;
        }
        return clone;
    }

    public async get() {
        return await fdw.getHead(this.cardType, this.filterOptions);
    }
}
