import fdw from '@/worker/fdw';

export class FilteredDatabaseHead {
	public id: number;
	constructor(id: number) {
		this.id = id;
	}
	public async getCards() {
		return await fdw.getCardsByHead(this.id);
	}
	public async getCount() {
		return await fdw.getCountByHead(this.id);
	}
	public async getSum(property: string) {
		return await fdw.getSumByHead(this.id,  property);
	}
	public async getSet(property: string) {
		return await fdw.getSetByHead(this.id,  property);
	}
	public async getMinMax(property: string) {
		return await fdw.getMinMaxByHead(this.id,  property);
	}
}
