import { FdRef } from '@/worker/fd/FilteredDatabaseRef';
import { FilterableCardName, StockBalanceCard } from '@/store/models.def';
import moment from 'moment';

export class FilteredDatabase {
	public static ref(cardType: FilterableCardName) {
		return new FdRef(cardType);
	}

	public static async getStockMovement(dateRange: [number, number], project?: string | [string]): Promise<number> {
		const allStockBalances: StockBalanceCard[] = await (
			await FilteredDatabase.ref('stockBalances')
				.get()).getCards();
		const filteredStockBalances: StockBalanceCard[] = await (
			await FilteredDatabase.ref('stockBalances')
				.includes('project', typeof project === 'string' ? [project] : project)
				.get()).getCards();

		const stockBalances: StockBalanceCard[] = project ? filteredStockBalances : allStockBalances;
		const [d0, d1] = dateRange.map((d) => moment(d));
		const previousMonth = d0.add(-1, 'month').month() + 1;
		const previousYear = d0.year();
		const currentMonth = d1.month() + 1;
		const currentYear = d1.year();

		const cardBefore = stockBalances.filter(
			(element) =>
				element.year === previousYear && element.month === previousMonth);
		// const amountBefore = cardBefore ? cardBefore.amount : 0;

		const cardAfter = stockBalances.filter(
			(element) => element.year === currentYear && element.month === currentMonth);

		// const amountAfter = cardAfter ? cardAfter.amount : 0;
		return cardBefore.reduce(
				(a, b) =>
						a + b.amount, 0)
				- cardAfter.reduce(
						(a, b) =>
								a + b.amount, 0);
	}

	public static async getStockMovement2(dateRange: [number, number]): Promise<number> {
		const stockBalances: StockBalanceCard[] = await (
			await (await FilteredDatabase.ref('stockBalances')).get()
		).getCards();

		const [d0, d1] = dateRange.map((d) => moment(d));
		const previousMonth = d0.add(-1, 'month').month() + 1;
		const previousYear = d0.year();
		const currentMonth = d1.month() + 1;
		const currentYear = d1.year();
		const cardBefore = stockBalances.find(
			(element) =>
				element.year === previousYear && element.month === previousMonth,
		);
		const amountBefore = cardBefore ? cardBefore.amount : 0;

		const cardAfter = stockBalances.find(
			(element) => element.year === currentYear && element.month === currentMonth,
		);
		const amountAfter = cardAfter ? cardAfter.amount : 0;
		return (amountAfter + amountBefore) / 2 ;
	}
}
