export class StateHistory {
	public static saveState(state: any, path: string, ...otherPaths: string[]) {
		StateHistory._instance.saveState(state, path, ...otherPaths);
	}
	public static loadState(path: string, ...otherPaths: string[]) {
		return StateHistory._instance.loadState(path, ...otherPaths);
	}
	public static clear() {
		StateHistory._instance.clear();
	}

	private static _instance = new StateHistory();

	private _dic: any = {};

	public saveState(state: any, path: string, ...otherPaths: string[]) {

		const dic = this._dic;
		if (!dic[path]) { dic[path] = {}; }
		let compartment = dic[path];
		for (const segment of otherPaths) {
			if (!compartment[segment]) { compartment[segment] = {}; }
			compartment = compartment[segment];
		}
		compartment.__state = state;
	}
	public loadState(path: string, ...otherPaths: string[]) {

		const dic = this._dic;
		let compartment = dic[path];
		if (!compartment) { return undefined; }
		for (const segment of otherPaths) {
			compartment = compartment[segment];
			if (!compartment) { return undefined; }
		}
		return compartment.__state;
	}

	public clear() {
		this._dic = {};
	}
}
